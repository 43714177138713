import React, { useEffect, useMemo, useRef, useState } from 'react'
import { TypeConfig, TypeStaticPage } from '../types'
import { useAdmin, useConfig, useDimensions } from './hooks'
import useResizeObserver from '@react-hook/resize-observer'
import ImageMenu from './ImageMenu'
import StaticPage from './StaticPage'
import Sequence from './Sequence'
import { useHistory } from 'react-router-dom'
import Navigation from './Navigation'
import GoogleMaps from './GoogleMaps'
import useGetParams from '../hooks/useGetParams'
import useDisplayContext from '../hooks/useDisplayContext'
import { PAGE_URL } from '../constants'
import Gallery from './Gallery'
import SequenceButtons from './SequenceButtons'
import SequenceNavigation from './SequenceNavigation'

const Home = React.memo(function Home(props: any) {
    const { root = {} as any, base, navigation_array = [], sequence_array, map_array = [], color_array, static_page_array } = useConfig()
    const { image_menu_id, target_id } = root
    const history = useHistory()
    const admin = useAdmin()
    const config = useConfig()

    const model = target_id ? target_id.split('-')[0] : 'image_menu'
    const id = target_id ? target_id.split('-')[1] : image_menu_id

    !navigation_array.length && root?.sequence_group_id && history.replace(`${base}${root.sequence_group_id}`)

    const { selected_navigation_item, gallery_id } = useGetParams()

    const { setDisplayContextValue } = useDisplayContext()

    const ref = useRef<any>()
    useResizeObserver(ref, (e) => {
        const { left, top, width, height } = ref.current.getBoundingClientRect()
        setDisplayContextValue({ left, top, width, height })
    })
    // console.log({ gallery_id })
    return (
        <main className={'flex flex-col justify-center items-center w-screen h-screen'}>
            {(navigation_array.length && (
                <div className="flex h-full w-full">
                    <Navigation />
                    <div
                        className={
                            'bg-white w-full flex flex-col justify-center items-center' +
                            (admin.enabled &&
                            selected_navigation_item?.target_model !== 'sequence' &&
                            selected_navigation_item?.target_model !== 'sequence_group'
                                ? ' pt-12'
                                : '')
                        }
                        ref={ref}
                    >
                        {selected_navigation_item?.target_model === 'image_menu' && <ImageMenu id={selected_navigation_item.target_id!} />}
                        {(selected_navigation_item?.target_model === 'sequence_group' || selected_navigation_item?.target_model === 'sequence') && (
                            <Sequence
                                sequence_id={
                                    selected_navigation_item?.target_model === 'sequence'
                                        ? selected_navigation_item.target_id!
                                        : sequence_array.find((s) => s.id === selected_navigation_item.target_id!)?.id
                                }
                            />
                        )}
                        {(selected_navigation_item?.target_model === 'html' && (
                            <div dangerouslySetInnerHTML={{ __html: selected_navigation_item?.target_html! }} className="h-full w-full" />
                        )) ||
                            null}
                        {selected_navigation_item?.target_model === 'map' && <GoogleMaps id={selected_navigation_item?.target_id!} />}
                        {selected_navigation_item?.target_model === 'static_page' &&
                            static_page_array.find((sp: TypeStaticPage) => sp.id === selected_navigation_item?.target_id) && (
                                <iframe
                                    width="100%"
                                    height="100%"
                                    src={
                                        PAGE_URL + 'page/' + static_page_array.find((sp: TypeStaticPage) => sp.id === selected_navigation_item?.target_id)?.path
                                    }
                                ></iframe>
                            )}
                        {selected_navigation_item?.target_model === 'gallery' && gallery_id && (
                            <Gallery image_menu_id_array={config.gallery_array?.find((g) => g.id === gallery_id)?.target_id_array!} key={gallery_id} />
                        )}
                    </div>
                </div>
            )) || (
                <>
                    {(model === 'static_page' && <StaticPage id={id} />) || null}
                    {(model === 'image_menu' && <ImageMenu id={id} />) || null}
                    {(model === 'sequence' && (
                        <>
                            <Sequence sequence_id={id} />
                            <SequenceNavigation />
                            <SequenceButtons />
                        </>
                    )) ||
                        null}
                </>
            )}
        </main>
    )
})

export default Home
